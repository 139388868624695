import {
  COMMUNITY_USER_TAG_WITHOUT_USER_FRAGMENT,
} from '@/graphql/_Fragments/CommunityUserTag/WithoutUser';

export const SUB_EDITION_WITH_TAGS_FRAGMENT = `
  fragment subEditionWithTagsFragment on SubEdition {
    uid
    tags: _myTags(myUid: "%authUser%") {
      ...communityUserTagWithoutUserFragment
    }
  }
  ${COMMUNITY_USER_TAG_WITHOUT_USER_FRAGMENT}
`;
